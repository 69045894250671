<template id="company-layout">
  <default-layout>
    <template v-slot:main>
      <!-- This example requires Tailwind CSS v2.0+ -->
      <div
        class="bg-white mt-16 mx-auto max-w-5xl shadow-lg overflow-hidden sm:rounded-lg"
      >
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Applicant Information
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Personal details and application.
          </p>
        </div>
        <div class="border-t border-gray-200 bg-gray-50">
          <div>
            <div class="sm:hidden">
              <label for="tabs" class="sr-only">Select a tab</label>
              <select
                id="tabs"
                name="tabs"
                class="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
              >
                <option selected>My Account</option>

                <option>Company</option>

                <option>Team Members</option>

                <option>Billing</option>
              </select>
            </div>
            <div class="hidden sm:block">
              <div class="border-b border-gray-200">
                <nav class="-mb-px flex" aria-label="Tabs">
                  <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
                  <router-link
                    :to="{
                      name: 'company-overview',
                      params: { cvr: this.$route.params.cvr },
                    }"
                    class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                  >
                    Oversigt
                  </router-link>

                  <router-link
                    :to="{
                      name: 'company-accounting',
                      params: { cvr: this.$route.params.cvr },
                    }"
                    class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                  >
                    Regnskab
                  </router-link>

                  <router-link
                    :to="{
                      name: 'company-key-figures',
                      params: { cvr: this.$route.params.cvr },
                    }"
                    class=" border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                  >
                    Nøgletal
                  </router-link>

                  <router-link
                    :to="{
                      name: 'company-roles',
                      params: { cvr: this.$route.params.cvr },
                    }"
                    class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                  >
                    Roller
                  </router-link>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <main class="px-4 py-5 sm:px-6">
          <slot name="main"></slot>
        </main>
      </div>
    </template>
  </default-layout>
</template>

<script>
import defaultLayout from "@/layouts/default.vue";
export default {
  name: "companyLayout",

  data() {
    return {
      companyData: null,
    };
  },
  components: {
    defaultLayout,
  },
  mounted() {
    this.getCompany(this.$route.params.cvr);
  },
  methods: {
    getCompany(search) {
      this.$axios
        .get("/virksomhed/" + search, {})
        .then((response) => {
          this.companyData = response.data.hits[0];
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // always executed
        });
    },
  },
};
</script>

<style>
.router-link-active {
  @apply border-indigo-500 text-indigo-600 hover:border-indigo-500 hover:text-indigo-600;
}
</style>
